import React from 'react';
import AppHeader from '../app-header/app-header';

function Home() {
  return (
    <AppHeader />
  );
}

export default Home;
